<template>
	<el-dialog append-to-body title="" :visible.sync="showVipDialog" width="1000px" @close="onCloseVip">
		<el-row :gutter="20">
			<el-col :span="6" v-for="(item,index) in list">
				<div class="rel hand hide" :style="{border:info.id === item.id ? '2px solid #6E431A' : ''}" style="width: 221px;height: 75px;border-radius: 10px;" @click="changeVip(item)">
					<img class="abs" :src="$utils.formatImagePath(item.head_img)" style="width: 100%;left:0;top:0;bottom:0;right:0;z-index: 1;"/>
					<div class="rel padding" style="z-index: 2;" v-if="item.is_diy === 0">
						<div class="f-bth f22 brown-1">{{item.title}}</div>
						<div class="f22 brown-1"><span class="f14">￥</span>{{item.price}}</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="" v-if="info">
			<img :src="$utils.formatImagePath(info.pc_body_img)" style="width: 100%;"/>
		</div>
		<div class="bg-gold-line-2 padding-top-bottom-5 border-radius-3 hide light hand" v-if="info && info.is_diy === 0" @click="readyPay">
			<div class="text-center f16">确认购买<span class="margin-left-5 f-b">(会员有效期 {{info.vip_str}})</span></div>
			<div class="text-center f12 margin-top-5">会员发票问题请联系客服 {{service_tel}}</div>
		</div>
		<div class="f14 text-center border-radius-5 hide bg-line-blue-4 light padding-top-bottom" v-if="info && info.is_diy === 1">
			联系客服定制专属权益 {{service_tel}}
		</div>
		<el-dialog width="240px" title="扫码支付" :visible.sync="innerVisible" append-to-body @close="onCloseQrCode">
			<div v-if="info">
				<img :src="'data:image/jpeg;base64,'+info.qrcode" width="200"/>
			</div>
		</el-dialog>
	</el-dialog>
</template>

<script>
export default {
	name: "VipDialog",
	data() {
		return {
			innerVisible:false,
			showVipDialog:false,
			user_type:'',
			list:[],
			info:null,
			service_tel:this.$utils.localGet('service_tel'),
			timeId:null,
			oldVipEndTime:0
		}
	},
	props:{
		show:{
			type:Boolean,
			default:false
		}
	},
	created() {
		this.showVipDialog = this.show;
	},
	mounted() {
	},
	methods: {
		onCloseQrCode(){
			if(this.timeId){
				clearInterval(this.timeId);
				this.timeId = null;
			}
		},
		readyPay(){
			this.innerVisible = true;
			this.timeId = setInterval(this.checkVipTime, 2000)
		},
		changeVip(item){
			this.info = item;
		},
		onCloseVip(){
			this.list = [];
			this.info = null;
			if(this.timeId){
				clearInterval(this.timeId);
				this.timeId = null;
			}
			this.$emit('update:show', false)
		},
		getList() {
			let _this = this;
			this.$http.httpPost({
				url: '/VipPrice/listAll',
				datas: {state:1,user_type:_this.user_type,orderBy:'sort',sortBy:'asc'},
				success: (res) => {
					_this.list = res;
					_this.info = res.length > 0 ? res[0] : null;
				}
			});
		},
		getVipTime() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUser/getProfile',
				datas: null,
				success: (res) => {
					_this.oldVipEndTime = res.vip_end_time;
				}
			});
		},
		checkVipTime() {
			let _this = this;
			this.$http.httpPost({
				url: '/AuthUser/getProfile',
				datas: null,
				success: (res) => {
					if(res.vip_end_time !== _this.oldVipEndTime){
						_this.$message.success('购买成功');
						_this.showVipDialog = false;
						// clearInterval(_this.timeId);
						// _this.timeId = null;
						if(_this.timeId){
							clearInterval(_this.timeId);
							_this.timeId = null;
						}

						window.location.reload();
					}
				}
			});
		},
	},
	watch:{
		show(n,o){
			this.user_type = this.$user.getUserType();
			this.showVipDialog = n;

			if(n){
				this.getList();
				this.getVipTime();
			}
		}
	}
}
</script>
