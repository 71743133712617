<template>
    <div class="rel">
        <div class="rel" style="overflow-x: hidden;height: 630px;z-index: 1;">
            <div class="abs bg-blue-line-3" style="margin-left:-10%;top:-670px;width:120%;height: 1300px;border-radius:0 0 50% 50%;z-index: 1;"></div>
        </div>
        <div class="wrap rel" style="z-index: 2;margin-top:-630px;">
            <div class="cl margin-top-50">
                <div class="y border-w-1 border-light light border-radius hide text-center f18" style="width: 225px;height: 45px;line-height: 45px;">
                    <span class="iconfont icon-dianhua light f18 margin-right"></span>
                    <span class="">{{setting.service_tel}}</span>
                </div>
            </div>
            <div class="text-center margin-top-60">
                <img src="/images/logo_light.png" width="480"/>
            </div>
            <slot></slot>
            <div class="cl margin-top-30 bg-gray-6" style="padding:43px 0 40px 0;height: 280px;">
                <div class="z" style="width: 32.7%">
                    <div class="text-center f18 dark-2 f-sy">客户服务</div>
                    <div class="cl"></div>
                    <div class="text-center" style="margin-top: 26px;">
                        <span class="hand inline-block rel hover-show" style="margin-right: 32px;">
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="hover">
                                <div class="hide" style="height:84px;width:84px;border:1px solid #595757" slot="reference">
                                    <img src="/images/s2.png"/>
                                </div>
                                <div class="f16">
                                    <div class="cl text-left f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>客服热线：{{setting.service_tel}}
                                    </div>
                                    <div class="cl text-left margin-top f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>会员咨询：{{setting.vip_tel}}
                                    </div>
                                    <div class="cl text-left margin-top f-sy">
                                        <img src="/images/phone2.png" class="margin-right"/>招标发布：{{setting.bids_push_tel}}
                                    </div>
                                </div>
                              </el-popover>
                            <div class="margin-top dark-2 f16 text-center f-sy">客服热线</div>
                        </span>
                        <span class="hand inline-block rel hover-show" style="margin-right: 32px;">
                            <el-popover
                                placement="bottom"
                                width="106"
                                trigger="hover">
                                <div class="hide" style="height:84px;width:84px;border:1px solid #595757" slot="reference">
                                    <img src="/images/s1.png"/>
                                </div>
                                <div class="">
                                    <img :src="setting.qq_service_qrcode.indexOf('http')>= 0 ? setting.qq_service_qrcode : $config.assetsUrl + setting.qq_service_qrcode" width="80" height="80" v-if="setting.qq_service_qrcode"/>
                                </div>
                              </el-popover>
                            <div class="margin-top dark-2 f16 text-center f-sy">在线客服</div>
                        </span>
                    </div>
                </div>
                <div class="border-right z margin-top-30" style="width: 0;height: 150px;"></div>
                <div class="z" style="width: 34.6%;padding:0 60px;">
                    <div class="text-center f18 dark-2 f-sy">中国物流招标网</div>
                    <div class="dark-2 margin-top-20 f16 f-sy" style="line-height: 26px;letter-spacing: 0.2px;">专注物流招标15年，致力于为商贸企业、制造企业提供招标信息的精准投放和物流外包咨询服务，为物流企业提供及时准确的招标信息和推送服务。</div>
                </div>
                <div class="border-right z margin-top-30" style="width: 0;height: 150px;"></div>
                <div class="z" style="width: 31.9%">
                    <div class="text-center f18 dark-2 f-sy">关注我们</div>
                    <div class="cl"></div>
                    <div class="text-center" style="margin-top:20px;">
                        <span class="inline-block" style="margin-right: 38px;" v-if="setting.weixin_app_qrcode">
                            <div><img :src="setting.weixin_app_qrcode.indexOf('http') >= 0 ? setting.weixin_app_qrcode : $config.assetsUrl + setting.weixin_app_qrcode" height="97" width="97"/></div>
                            <div class="cl"></div>
                            <div class="dark-2 margin-top-5 f16 f-sy">订阅号</div>
                        </span>
                        <span class="inline-block" v-if="setting.weixin_service_qrcode">
                            <div><img :src="setting.weixin_service_qrcode.indexOf('http') >= 0 ? setting.weixin_service_qrcode : $config.assetsUrl + setting.weixin_service_qrcode" height="97" width="97"/></div>
                            <div class="dark-2 margin-top-5 f16 f-sy">服务号</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center bg-blue f14" style="z-index:2;height: 134px;padding-top:35px;">
            <div class="light f-sy">友情链接：<template v-for="(item,index) in friendList"><a class="light hover-under no-visited" target="_blank" :href="item.url.indexOf('http') >= 0 ? item.url : ''">{{item.title}}</a><template v-if="index + 1 < friendList.length"> | </template></template></div>
            <div class="light hover-under no-visited light-sun margin-top-15 f-sy" v-html="setting.page_footer" v-if="setting.page_footer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserLayout",
    data(){
        return{
            setting:{
                weixin_service_qrcode:'',
                weixin_app_qrcode:'',
                qq_service_qrcode:'',
                service_tel:'',
                vip_tel:'',
                bids_push_tel:'',
                service_qq:'',
                page_footer:''
                // side_qrcode:'',
                // side_qrcode_width:'',
                // side_bottom:''
            },
            friendList:[]
        }
    },
    mounted() {
        this.getSystem();
        this.getFriendLink();
    },
    methods:{
        getSystem(){
            let _this = this;

            let _skeys = [];

            for (let k in this.setting) {
                _skeys.push(k);
            }

            this.$http.httpPost({
                url: '/SystemSetting/getSystem',
                datas: {skey: _skeys},
                success: (res) => {
                    _this.setting = res;
                }
            });
        },
        getFriendLink(){
            let _this = this;

            this.$http.httpPost({
                url: '/FriendLink/listAll',
                datas: {state: 1,orderBy:'sort',sortBy:'asc'},
                success: (res) => {
                    _this.friendList = res;
                }
            });
        },
    }
}
</script>
